<template>
  <div class="flex items-center">
    <div>
      <erp-select :options="options" v-model="criterio.comparativo" />
    </div>
    <div class="m-l">
      <erp-input v-model="criterio.value" v-money="money" />
    </div>
  </div>
</template>

<script>
import {lessThan} from './matches/lessThan'
import {ErpSelect, ErpInput} from 'uloc-vue-plugin-erp'
import {REAL_BRL} from '../../../../utils/money'
import {VMoney} from 'v-money'
import criteriaMixin from './criteriaMixin'

export default {
  name: 'Valor',
  mixins: [criteriaMixin],
  directives: {money: VMoney},
  props: {
    criterio: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      options: [
        ...lessThan
      ],
      money: REAL_BRL,
    }
  },
  computed: {
  },
  methods: {
  },
  components: {ErpSelect, ErpInput}
}
</script>
