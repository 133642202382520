export const lessThan = [
  {
    label: 'É menor que',
    value: 'less-than'
  },
  {
    label: 'É maior que',
    value: 'bigger-than'
  }
]
